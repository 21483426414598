import { FC, useRef } from 'react';

import { CurrentSteps } from '../components/CurrentSteps';
import { LeadershipSection } from '../components/LeadershipSection';
import { OurSolutions } from '../components/OurSolutions';
import { PlatformVisual } from '../components/PlatformVisual';
import { ProblemAndSolution } from '../components/ProblemAndSolution';
import { QuestionsAndAnswers } from '../components/QuestionsAndAnswers';
import { QuoteFromCEO } from '../components/QuoteFromCEO';
import { TopImageAndDescription } from '../components/TopImageAndDescription';
import { TopNav } from '../components/TopNav';

export const MainPage: FC = () => {
  const solutionsRef = useRef<HTMLDivElement>(null);
  const teamRef = useRef<HTMLDivElement>(null);
  // const contactRef = useRef<HTMLDivElement>(null);

  const scrollToSolutions = () => solutionsRef.current?.scrollIntoView();
  const scrollToLeadership = () => teamRef.current?.scrollIntoView();
  // const scrollToContact = () => contactRef.current?.scrollIntoView();

  return (
    <div>
      <TopNav
        scrollToSolutions={scrollToSolutions}
        scrollToLeadership={scrollToLeadership}
        // scrollToContact={scrollToContact}
        scrollToContact={() => null}
      />
      <TopImageAndDescription scrollToSolutions={scrollToSolutions} />
      <ProblemAndSolution />
      <OurSolutions divRef={solutionsRef} />
      <QuestionsAndAnswers />
      <PlatformVisual />
      <LeadershipSection divRef={teamRef} />
      <QuoteFromCEO />
      <CurrentSteps />
      {/* <CallToAction divRef={contactRef} /> */}
      <footer className="bg-gray-400 min-h-[100px] m-auto pt-5 pr-20 sm:pr-10">
        <img src={process.env.PUBLIC_URL + 'BEPLogo.png'} className="max-w-[500px] min-w-[25px] float-right" />
      </footer>
    </div>
  );
};
